import type { ReactNode } from "react"

import styles from "./subheader.module.css"

/**
 * Renders the header displaying information about the institution members.
 */
export const Subheader = ({
  text,
  children,
  buttons,
}: {
  text?: string
  readOnly?: boolean
  children?: ReactNode
  buttons?: ReactNode
}) => (
  <div className={styles["wrapper"]}>
    <h2 className={styles["subheader"]}>{children ? children : text}</h2>
    <div className={styles["buttons"]}>{buttons}</div>
  </div>
)
