import * as RadixAvatar from "@radix-ui/react-avatar"

import styles from "./Avatar.module.css"

type AvatarProps = {
  size?: number
  src?: string
  fallback?: string
}

export function Avatar({ size, src, fallback }: AvatarProps) {
  return (
    <RadixAvatar.Root
      data-avatar
      className={styles.avatar}
      style={{ width: size, height: size }}
    >
      <RadixAvatar.Image src={src} alt={`Avatar for ${fallback}`} />
      <RadixAvatar.Fallback>{fallback}</RadixAvatar.Fallback>
    </RadixAvatar.Root>
  )
}
