import type { ReactNode } from "react"

import styles from "./PageHeader.module.css"

type PageHeaderProps = {
  title?: string | ReactNode
  description?: string | ReactNode
  children?: ReactNode
  href?: string
}

export function PageHeader({
  title,
  description,
  children,
  href,
}: PageHeaderProps) {
  return (
    <div className={styles["pageHeader"]}>
      <div className={styles["pageHeaderTitleWrapper"]}>
        <h1 className={styles["pageHeaderTitle"]}>{title}</h1>
        {href ? (
          <a href={href} className={styles["pageHeaderSubtitle"]}>
            {description}
          </a>
        ) : (
          <p className={styles["pageHeaderSubtitle"]}>{description}</p>
        )}
      </div>
      <div className="script-widgets right">{children}</div>
    </div>
  )
}
